import { createSlice } from '@reduxjs/toolkit';
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';

const initialState = {
  isLoading: false,
  isSuccess: false,
  approveTimesheetError: false,
  approveCircuitMileageError: false,
  error: false,
  errorResponse: {},
  recordNmbr: '',
  ponum: '',
  caastatus: '',
  contractNmbr: '',
  crewNmbr: '',
  crewType: '',
  workAreaId: '',
  worktype: '',
  masterProjectNumber: '',
  authType: '',
  authNmbr: '',
  circuitNmbr: '',
  subcircuit: '',
  milescomplete: '',
  workrequest: '',
  weekEndDate: '',
  dcsNmbr: '',
  invoiceNmbr: null,
  remarks: '',
  contCompNmbr: '',
  version: '',
  internetNmbr: '',
  jobName: '',
  jobDescription: '',
  jobCity: '',
  contName: '',
  contDtApprovedby: '',
  contRefNumeric: '',
  vendorTimesheet: '',
  changeStatus: '',
  facilityCategoryId: '',
  enteredBy: '',
  enteredDate: '',
  updatedBy: '',
  updatedDate: '',
  materialStatus: '',
  ocNmbr: '',
  uvlYear: '',
  uvlMonth: '',
  workStartDate: '',
  workEndDate: '',
  projectManager: '',
  tcr: '',
  leadEngineer: '',
  rowAgent: '',
  estimate: '',
  vendorSubmittedDate: '',
  transWorkorder: '',
  isExcelUpload: '',
  timesheetType: '',
  compFactorId: '',
  partialPayAmount: '',
  wr: '',
  jobCity: '',
  jobDescription: '',
  cdDist: '',
  ppAuthNmbr: '',
  penaltyFlag: '',
  rejectReason: '',
  siteid: '',
  orgid: '',
  revisionnum: '',
  poid: '',
  activityCode: '',
  activityDescription: '',
  comments: '',
  uvl: false,
  submittedByAep: false,
  jobAuthorization: {},
  classificationList: [],
  timesheetAttachments: [],
  timesheetAccounts: [],
  timesheetApprovals: [],
  [SERVICE_TYPES.LABOR]: [],
  [SERVICE_TYPES.EQUIPMENT]: [],
  [SERVICE_TYPES.REIMBURSABLE_MARKUP]: [],
  [SERVICE_TYPES.UNIT_PRICE_TASK]: [],
  [SERVICE_TYPES.CU]: [],
  [SERVICE_TYPES.LUMPSUM]: [],
};

const timesheetDetailSlice = createSlice({
  name: 'timesheetDetailSlice',
  initialState,
  reducers: {
    fetchTimesheetDetail(state) {
      state.isLoading = false;
    },

    fetchJobAuthorization(state) {
      state.isLoading = false;
    },

    updateTimesheet(state) {
      state.isLoading = false;
    },

    getWorkOrderDetails(state, action) {
      state.isLoading = false;
    },

    setClassificationList(state, action) {
      const stateValues = { ...state };
      stateValues.classificationList = action.payload;
      return { ...stateValues };
    },

    setTimesheetApprovals(state, action) {
      const stateValues = { ...state };
      stateValues.timesheetApprovals = action.payload;
      return { ...stateValues };
    },

    setTimesheetDetail(state, action) {
      const responseData = action.payload;
      const stateValues = { ...state, ...responseData };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setApproveTimesheetError(state, action) {
      const stateValues = { ...state };
      stateValues.approveTimesheetError = action.payload;
      return { ...stateValues };
    },

    setApproveCircuitMileageError(state, action) {
      const stateValues = { ...state };
      stateValues.approveCircuitMileageError = action.payload;
      return { ...stateValues };
    },

    setJobAuthorization(state, action) {
      const responseData = action.payload;
      const stateValues = { ...state, jobAuthorization: responseData };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setTimesheetSuccess(state, action) {
      const stateValues = { ...state };
      stateValues.isSuccess = action.payload;
      return { ...stateValues };
    },

    resetTimesheetDetail() {
      return { ...initialState };
    },

    unsetTimesheetDetailError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    updatePOCostActualAndDollar(state, action) {
      const stateValues = { ...state };
      return { ...stateValues };
    },

    setTimesheetDetailError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },
  },
});

export const {
  fetchTimesheetDetail,
  fetchJobAuthorization,
  updateTimesheet,
  updatePOCostActualAndDollar,
  getWorkOrderDetails,
  setTimesheetDetail,
  resetTimesheetDetail,
  unsetTimesheetDetailError,
  setTimesheetDetailError,
  setTimesheetSuccess,
  setClassificationList,
  setTimesheetApprovals,
  setApproveTimesheetError,
  setApproveCircuitMileageError,
  setJobAuthorization,
} = timesheetDetailSlice.actions;

export default timesheetDetailSlice.reducer;
