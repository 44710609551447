import { Divider } from 'components/common/Divider/Divider';
import { ModuleACL, UserType } from 'enums/entitlements.ts';
import { AuthType } from 'enums/jobAuth.ts';
import { TimesheetStatus } from 'enums/statuses.ts';
import { Col, Form, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import ReactSelect from 'react-select';
import { IsAllowedACL } from 'utils/aclHelper';
import { isEmptyVal } from 'utils/utils';

const DistributionAccountingHeader = props => {
  const user = useSelector(state => state.auth);
  const lookup = useSelector(state => state.lookup);
  const timesheet = useSelector(state => state.timesheetDetail);
  const isMobile = useMediaQuery({ query: '(max-width: 834px)' });

  const workAreaList = lookup?.workArea
    ?.slice()
    .sort((option1, option2) =>
      String(option1?.workAreaShortDesc)
        .trim()
        .localeCompare(String(option2?.workAreaShortDesc).trim()),
    );

  const {
    control,
    trigger,
    errors,
    setValue,
    getValues,
    selectedOpco,
    handleAuthorizationChange,
    handleWorkArea,
    handleOpco,
    handleWorkType,
    milesCompleteError,
    computeMilesCompleteError,
    debouncedAuthorizationChange,
    debouncedCircuitChange,
  } = props;

  const isDisabled = () => {
    let condition = false;

    if (
      timesheet?.caastatus === TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED
    ) {
      if (user?.configObject?.userrole === 'admin') {
        condition = false;
      } else {
        condition = !IsAllowedACL(
          user,
          ModuleACL.TIMESHEET,
          'can_submit_new_timesheet',
        );
      }
    } else {
      condition =
        timesheet?.caastatus === TimesheetStatus.TIMESHEET_APPROVED ||
        ([
          TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
          TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
        ].includes(timesheet?.caastatus) &&
          user?.userType === UserType.VENDOR);
    }
    return condition;
  };

  const isCUTimesheet = () => {
    let filteredList = timesheet?.classificationList.filter(obj =>
      obj.name.includes('timesheetCus'),
    );

    if (filteredList.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="distributionAcctHeader">
        <Divider style={{ margin: '40px 40px 20px 0' }} />

        <div style={{ display: 'flex' }}>
          <Row>
            <Col className="sm2">
              <Form.Group>
                <Form.Label>OPCO / State</Form.Label>
                <Controller
                  control={control}
                  name="ocNmbr"
                  render={({ onChange, value, name, ref }) => (
                    <Form.Select
                      value={selectedOpco ? selectedOpco : getValues('ocNmbr')}
                      onChange={handleOpco}
                      disabled={isDisabled()}
                    >
                      <option value="">Select</option>
                      {lookup?.operatingCompany?.map((obj, index) => (
                        <option key={index} value={obj.ocNmbr}>
                          {`${obj.companyAbbr} / ${obj.stateCode}`}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
              </Form.Group>
            </Col>
            <Col className="sm1">
              <Form.Group
                style={{
                  width: '328px',
                  marginRight: '12px',
                }}
              >
                <Form.Label>Work Area</Form.Label>
                <Controller
                  control={control}
                  name="workAreaId"
                  render={({ onChange, value, name, ref }) => (
                    <Form.Select
                      value={getValues('workAreaId')}
                      onChange={handleWorkArea}
                      disabled={isDisabled()}
                    >
                      <option value="">Select</option>
                      {workAreaList?.map((obj, index) => (
                        <option key={index} value={obj.workAreaId}>
                          {obj.workAreaShortDesc}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
              </Form.Group>
            </Col>
            <Col className="sm1">
              <Form.Group>
                <Form.Label>Work Type</Form.Label>
                <Controller
                  control={control}
                  name="worktype"
                  render={({ onChange, value, name, ref }) => (
                    <Form.Select
                      value={getValues('worktype')}
                      onChange={handleWorkType}
                      disabled={isDisabled()}
                    >
                      <option value="">Select</option>
                      {lookup?.workType?.map((obj, index) => (
                        <option key={index} value={obj.worktype}>
                          {obj.worktype}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
              </Form.Group>
            </Col>
            <Col className="sm2">
              <Form.Group>
                <Form.Label>Crew Types</Form.Label>
                <Controller
                  control={control}
                  name="crewType"
                  render={({ onChange, value, name, ref }) => (
                    <Form.Select
                      value={getValues('crewType')}
                      onChange={e => {
                        setValue('crewType', e.target.value);
                        trigger('crewType');
                      }}
                      disabled={isDisabled()}
                    >
                      <option value="">Select</option>
                      {lookup?.crewType?.map((obj, index) => (
                        <option key={index} value={obj.crewType}>
                          {obj.crewType}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
              </Form.Group>
            </Col>
            <Col className="sm2">
              <Form.Group>
                <Form.Label>Crew #</Form.Label>
                <Controller
                  control={control}
                  name="crewNmbr"
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      value={value}
                      onChange={e => {
                        setValue('crewNmbr', e.target.value);
                        trigger('crewNmbr');
                      }}
                      ref={ref}
                      disabled={isDisabled()}
                    />
                  )}
                />
                <div className="errorMessage">
                  {errors?.['crewNmbr']?.['message']}
                </div>
              </Form.Group>
            </Col>
            <Col className="sm2">
              <Form.Group>
                <Form.Label>Master Project</Form.Label>
                <Controller
                  control={control}
                  name="masterProjectNumber"
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      value={value}
                      onChange={e =>
                        setValue('masterProjectNumber', e.target.value)
                      }
                      ref={ref}
                      type="text"
                      disabled={isDisabled()}
                    />
                  )}
                />
                <div className="errorMessage">
                  {errors?.['masterProjectNumber']?.['message']}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <Divider style={{ margin: '40px 40px 20px 0' }} />

        <div style={{ display: 'flex' }}>
          <Row>
            <Col className="sm2">
              <Form.Group>
                <Form.Label>Auth Type</Form.Label>
                <Controller
                  control={control}
                  name="authType"
                  render={({ onChange, value, name, ref }) => (
                    <Form.Select
                      value={getValues('authType')}
                      onChange={e => handleAuthorizationChange(e, 'authType')}
                      disabled={isDisabled()}
                    >
                      <option value="">Select</option>
                      <option value={AuthType.TIME_AND_MATERIAL}>
                        {AuthType.TIME_AND_MATERIAL}
                      </option>
                      <option value={AuthType.UNIT_PRICE}>
                        {AuthType.UNIT_PRICE}
                      </option>
                    </Form.Select>
                  )}
                />
              </Form.Group>
            </Col>
            <Col className="sm2">
              <Form.Group>
                <Form.Label>Authorization</Form.Label>
                <ReactSelect
                  id="jobAuthReactSelect"
                  style={
                    isMobile
                      ? { display: 'block', width: '100%' }
                      : { display: 'block' }
                  }
                  classNamePrefix="poSelect"
                  value={lookup?.jobAuthorizations.find(
                    c => c.value === getValues('authNmbr'),
                  )}
                  isDisabled={
                    ['', AuthType.TIME_AND_MATERIAL].includes(
                      getValues('authType'),
                    ) || isDisabled()
                  }
                  isClearable={false}
                  isSearchable={true}
                  name="jobauth"
                  options={lookup?.jobAuthorizations}
                  menuPosition="absolute"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: '16px',
                    }),
                  }}
                  maxMenuHeight={600}
                  onChange={e => handleAuthorizationChange(e, 'authNmbr')}
                  onInputChange={e => debouncedAuthorizationChange(e)}
                />
                <div className="errorMessage">
                  {errors?.['authNmbr']?.['message']}
                </div>
              </Form.Group>
            </Col>
            <Col className="sm2">
              <Form.Group>
                <Form.Label>Circuit Number</Form.Label>
                <ReactSelect
                  id="circuitReactSelect"
                  style={
                    isMobile
                      ? { display: 'block', width: '100%' }
                      : { display: 'block' }
                  }
                  classNamePrefix="poSelect"
                  value={lookup?.circuitList.find(
                    c => c.value === getValues('circuitnum'),
                  )}
                  isDisabled={isDisabled()}
                  isClearable={false}
                  isSearchable={true}
                  name="circuit"
                  options={lookup?.circuitList}
                  menuPosition="absolute"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: '16px',
                    }),
                  }}
                  maxMenuHeight={600}
                  onChange={e => setValue('circuitnum', e?.value)}
                  onInputChange={e => debouncedCircuitChange(e)}
                />
              </Form.Group>
            </Col>
            <Col className="sm2">
              <Form.Group>
                <Form.Label>Sub Circuit Number</Form.Label>
                <Controller
                  control={control}
                  name="subcircuit"
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      value={value}
                      onChange={e => {
                        setValue('subcircuit', e.target.value);
                        trigger('subcircuit');
                      }}
                      ref={ref}
                      type="text"
                      disabled={isDisabled()}
                    />
                  )}
                />
                <div className="errorMessage">
                  {errors?.['subcircuit']?.['message']}
                </div>
              </Form.Group>
            </Col>
            <Col className="sm2">
              <Form.Group>
                <Form.Label>Miles Completed</Form.Label>
                <Controller
                  control={control}
                  name="milescomplete"
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      value={value}
                      onChange={e => {
                        setValue('milescomplete', e.target.value);
                        computeMilesCompleteError();
                        trigger('milescomplete');
                      }}
                      ref={ref}
                      type="text"
                      disabled={
                        [
                          TimesheetStatus.TIMESHEET_CANCELLED,
                          TimesheetStatus.TIMESHEET_APPROVED,
                        ].includes(timesheet?.caastatus) || isDisabled()
                      }
                    />
                  )}
                />

                {!isEmptyVal(errors?.['milescomplete']?.['message']) && (
                  <div className="errorMessage">
                    {errors?.['milescomplete']?.['message']}
                  </div>
                )}
                {!isEmptyVal(milesCompleteError) && (
                  <div className="errorMessage">{milesCompleteError}</div>
                )}
              </Form.Group>
            </Col>

            {isCUTimesheet() && (
              <Col className="sm2">
                <Form.Group>
                  <Form.Label>Work Request</Form.Label>
                  <Controller
                    control={control}
                    name="wr"
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Form.Control
                        value={value}
                        onChange={e => {
                          setValue('wr', e.target.value);
                        }}
                        ref={ref}
                        type="text"
                        disabled={true}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            )}
            {isCUTimesheet() && (
              <Col className="sm2">
                <Form.Group>
                  <Form.Label>Job City</Form.Label>
                  <Controller
                    control={control}
                    name="jobCity"
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Form.Control
                        value={value}
                        onChange={e => {
                          setValue('jobCity', e.target.value);
                          trigger('jobCity');
                        }}
                        ref={ref}
                        type="text"
                        disabled={
                          [
                            TimesheetStatus.TIMESHEET_CANCELLED,
                            TimesheetStatus.TIMESHEET_APPROVED,
                          ].includes(timesheet?.caastatus) || isDisabled()
                        }
                      />
                    )}
                  />
                  <div className="errorMessage">
                    {errors?.['jobCity']?.['message']}
                  </div>
                </Form.Group>
              </Col>
            )}
            {isCUTimesheet() && (
              <Col sm={3} className="sm2">
                <Form.Group>
                  <Form.Label>Job Description</Form.Label>
                  <Controller
                    control={control}
                    name="jobDescription"
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Form.Control
                        value={value}
                        onChange={e => {
                          setValue('jobDescription', e.target.value);
                          trigger('jobDescription');
                        }}
                        ref={ref}
                        type="text"
                        disabled={
                          [
                            TimesheetStatus.TIMESHEET_CANCELLED,
                            TimesheetStatus.TIMESHEET_APPROVED,
                          ].includes(timesheet?.caastatus) || isDisabled()
                        }
                      />
                    )}
                  />
                  <div className="errorMessage">
                    {errors?.['jobDescription']?.['message']}
                  </div>
                </Form.Group>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export { DistributionAccountingHeader };
